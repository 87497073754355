<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('market.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>

              <b-row>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      :state="errors && errors.position ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                      readonly
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.icon.label')"
                    label-for="icon"
                    :state="errors && errors.icon ? false : null"
                  >
                    <b-input-group class="icon_select">
                      <b-input-group-prepend
                        is-text
                      >
                        <feather-icon
                          :icon="item.icon"
                          size="20"
                        />
                      </b-input-group-prepend>
                      <v-select
                        id="icon"
                        v-model="item.icon"
                        :multiple="false"
                        :options="icons"
                        :reduce="val => val.value"
                      >
                        <template #option="{ label }">
                          <feather-icon
                            :icon="label"
                            size="24"
                            class="mr-1"
                          />
                          {{ label }}
                        </template>
                      </v-select>
                    </b-input-group>
                    <b-form-invalid-feedback v-if="errors && errors.icon">
                      {{ errors.icon[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:faq-categories-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      icons: [
        { label: 'ActivityIcon', value: 'ActivityIcon' },
        { label: 'AirplayIcon', value: 'AirplayIcon' },
        { label: 'AlertCircleIcon', value: 'AlertCircleIcon' },
        { label: 'AlertOctagonIcon', value: 'AlertOctagonIcon' },
        { label: 'AlertTriangleIcon', value: 'AlertTriangleIcon' },
        { label: 'AlignCenterIcon', value: 'AlignCenterIcon' },
        { label: 'AlignJustifyIcon', value: 'AlignJustifyIcon' },
        { label: 'AlignLeftIcon', value: 'AlignLeftIcon' },
        { label: 'AlignRightIcon', value: 'AlignRightIcon' },
        { label: 'AnchorIcon', value: 'AnchorIcon' },
        { label: 'ApertureIcon', value: 'ApertureIcon' },
        { label: 'ArchiveIcon', value: 'ArchiveIcon' },
        { label: 'ArrowDownCircleIcon', value: 'ArrowDownCircleIcon' },
        { label: 'ArrowDownLeftIcon', value: 'ArrowDownLeftIcon' },
        { label: 'ArrowDownRightIcon', value: 'ArrowDownRightIcon' },
        { label: 'ArrowDownIcon', value: 'ArrowDownIcon' },
        { label: 'ArrowLeftCircleIcon', value: 'ArrowLeftCircleIcon' },
        { label: 'ArrowLeftIcon', value: 'ArrowLeftIcon' },
        { label: 'ArrowRightCircleIcon', value: 'ArrowRightCircleIcon' },
        { label: 'ArrowRightIcon', value: 'ArrowRightIcon' },
        { label: 'ArrowUpCircleIcon', value: 'ArrowUpCircleIcon' },
        { label: 'ArrowUpLeftIcon', value: 'ArrowUpLeftIcon' },
        { label: 'ArrowUpRightIcon', value: 'ArrowUpRightIcon' },
        { label: 'ArrowUpIcon', value: 'ArrowUpIcon' },
        { label: 'AtSignIcon', value: 'AtSignIcon' },
        { label: 'AwardIcon', value: 'AwardIcon' },
        { label: 'BarChart2Icon', value: 'BarChart2Icon' },
        { label: 'BarChartIcon', value: 'BarChartIcon' },
        { label: 'BatteryChargingIcon', value: 'BatteryChargingIcon' },
        { label: 'BatteryIcon', value: 'BatteryIcon' },
        { label: 'BellOffIcon', value: 'BellOffIcon' },
        { label: 'BellIcon', value: 'BellIcon' },
        { label: 'BluetoothIcon', value: 'BluetoothIcon' },
        { label: 'BoldIcon', value: 'BoldIcon' },
        { label: 'BookOpenIcon', value: 'BookOpenIcon' },
        { label: 'BookIcon', value: 'BookIcon' },
        { label: 'BookmarkIcon', value: 'BookmarkIcon' },
        { label: 'BoxIcon', value: 'BoxIcon' },
        { label: 'BriefcaseIcon', value: 'BriefcaseIcon' },
        { label: 'CalendarIcon', value: 'CalendarIcon' },
        { label: 'CameraOffIcon', value: 'CameraOffIcon' },
        { label: 'CameraIcon', value: 'CameraIcon' },
        { label: 'CastIcon', value: 'CastIcon' },
        { label: 'CheckCircleIcon', value: 'CheckCircleIcon' },
        { label: 'CheckSquareIcon', value: 'CheckSquareIcon' },
        { label: 'CheckIcon', value: 'CheckIcon' },
        { label: 'ChevronDownIcon', value: 'ChevronDownIcon' },
        { label: 'ChevronLeftIcon', value: 'ChevronLeftIcon' },
        { label: 'ChevronRightIcon', value: 'ChevronRightIcon' },
        { label: 'ChevronUpIcon', value: 'ChevronUpIcon' },
        { label: 'ChevronsDownIcon', value: 'ChevronsDownIcon' },
        { label: 'ChevronsLeftIcon', value: 'ChevronsLeftIcon' },
        { label: 'ChevronsRightIcon', value: 'ChevronsRightIcon' },
        { label: 'ChevronsUpIcon', value: 'ChevronsUpIcon' },
        { label: 'ChromeIcon', value: 'ChromeIcon' },
        { label: 'CircleIcon', value: 'CircleIcon' },
        { label: 'ClipboardIcon', value: 'ClipboardIcon' },
        { label: 'ClockIcon', value: 'ClockIcon' },
        { label: 'CloudDrizzleIcon', value: 'CloudDrizzleIcon' },
        { label: 'CloudLightningIcon', value: 'CloudLightningIcon' },
        { label: 'CloudOffIcon', value: 'CloudOffIcon' },
        { label: 'CloudRainIcon', value: 'CloudRainIcon' },
        { label: 'CloudSnowIcon', value: 'CloudSnowIcon' },
        { label: 'CloudIcon', value: 'CloudIcon' },
        { label: 'CodeIcon', value: 'CodeIcon' },
        { label: 'CodepenIcon', value: 'CodepenIcon' },
        { label: 'CodesandboxIcon', value: 'CodesandboxIcon' },
        { label: 'CoffeeIcon', value: 'CoffeeIcon' },
        { label: 'ColumnsIcon', value: 'ColumnsIcon' },
        { label: 'CommandIcon', value: 'CommandIcon' },
        { label: 'CompassIcon', value: 'CompassIcon' },
        { label: 'CopyIcon', value: 'CopyIcon' },
        { label: 'CornerDownLeftIcon', value: 'CornerDownLeftIcon' },
        { label: 'CornerDownRightIcon', value: 'CornerDownRightIcon' },
        { label: 'CornerLeftDownIcon', value: 'CornerLeftDownIcon' },
        { label: 'CornerLeftUpIcon', value: 'CornerLeftUpIcon' },
        { label: 'CornerRightDownIcon', value: 'CornerRightDownIcon' },
        { label: 'CornerRightUpIcon', value: 'CornerRightUpIcon' },
        { label: 'CornerUpLeftIcon', value: 'CornerUpLeftIcon' },
        { label: 'CornerUpRightIcon', value: 'CornerUpRightIcon' },
        { label: 'CpuIcon', value: 'CpuIcon' },
        { label: 'CreditCardIcon', value: 'CreditCardIcon' },
        { label: 'CropIcon', value: 'CropIcon' },
        { label: 'CrosshairIcon', value: 'CrosshairIcon' },
        { label: 'DatabaseIcon', value: 'DatabaseIcon' },
        { label: 'DeleteIcon', value: 'DeleteIcon' },
        { label: 'DiscIcon', value: 'DiscIcon' },
        { label: 'DivideCircleIcon', value: 'DivideCircleIcon' },
        { label: 'DivideSquareIcon', value: 'DivideSquareIcon' },
        { label: 'DivideIcon', value: 'DivideIcon' },
        { label: 'DollarSignIcon', value: 'DollarSignIcon' },
        { label: 'DownloadCloudIcon', value: 'DownloadCloudIcon' },
        { label: 'DownloadIcon', value: 'DownloadIcon' },
        { label: 'DribbbleIcon', value: 'DribbbleIcon' },
        { label: 'DropletIcon', value: 'DropletIcon' },
        { label: 'Edit2Icon', value: 'Edit2Icon' },
        { label: 'Edit3Icon', value: 'Edit3Icon' },
        { label: 'EditIcon', value: 'EditIcon' },
        { label: 'ExternalLinkIcon', value: 'ExternalLinkIcon' },
        { label: 'EyeOffIcon', value: 'EyeOffIcon' },
        { label: 'EyeIcon', value: 'EyeIcon' },
        { label: 'FacebookIcon', value: 'FacebookIcon' },
        { label: 'FastForwardIcon', value: 'FastForwardIcon' },
        { label: 'FeatherIcon', value: 'FeatherIcon' },
        { label: 'FigmaIcon', value: 'FigmaIcon' },
        { label: 'FileMinusIcon', value: 'FileMinusIcon' },
        { label: 'FilePlusIcon', value: 'FilePlusIcon' },
        { label: 'FileTextIcon', value: 'FileTextIcon' },
        { label: 'FileIcon', value: 'FileIcon' },
        { label: 'FilmIcon', value: 'FilmIcon' },
        { label: 'FilterIcon', value: 'FilterIcon' },
        { label: 'FlagIcon', value: 'FlagIcon' },
        { label: 'FolderMinusIcon', value: 'FolderMinusIcon' },
        { label: 'FolderPlusIcon', value: 'FolderPlusIcon' },
        { label: 'FolderIcon', value: 'FolderIcon' },
        { label: 'FramerIcon', value: 'FramerIcon' },
        { label: 'FrownIcon', value: 'FrownIcon' },
        { label: 'GiftIcon', value: 'GiftIcon' },
        { label: 'GitBranchIcon', value: 'GitBranchIcon' },
        { label: 'GitCommitIcon', value: 'GitCommitIcon' },
        { label: 'GitMergeIcon', value: 'GitMergeIcon' },
        { label: 'GitPullRequestIcon', value: 'GitPullRequestIcon' },
        { label: 'GithubIcon', value: 'GithubIcon' },
        { label: 'GitlabIcon', value: 'GitlabIcon' },
        { label: 'GlobeIcon', value: 'GlobeIcon' },
        { label: 'GridIcon', value: 'GridIcon' },
        { label: 'HardDriveIcon', value: 'HardDriveIcon' },
        { label: 'HashIcon', value: 'HashIcon' },
        { label: 'HeadphonesIcon', value: 'HeadphonesIcon' },
        { label: 'HeartIcon', value: 'HeartIcon' },
        { label: 'HelpCircleIcon', value: 'HelpCircleIcon' },
        { label: 'HexagonIcon', value: 'HexagonIcon' },
        { label: 'HomeIcon', value: 'HomeIcon' },
        { label: 'ImageIcon', value: 'ImageIcon' },
        { label: 'InboxIcon', value: 'InboxIcon' },
        { label: 'InfoIcon', value: 'InfoIcon' },
        { label: 'InstagramIcon', value: 'InstagramIcon' },
        { label: 'ItalicIcon', value: 'ItalicIcon' },
        { label: 'KeyIcon', value: 'KeyIcon' },
        { label: 'LayersIcon', value: 'LayersIcon' },
        { label: 'LayoutIcon', value: 'LayoutIcon' },
        { label: 'LifeBuoyIcon', value: 'LifeBuoyIcon' },
        { label: 'Link2Icon', value: 'Link2Icon' },
        { label: 'LinkIcon', value: 'LinkIcon' },
        { label: 'LinkedinIcon', value: 'LinkedinIcon' },
        { label: 'ListIcon', value: 'ListIcon' },
        { label: 'LoaderIcon', value: 'LoaderIcon' },
        { label: 'LockIcon', value: 'LockIcon' },
        { label: 'LogInIcon', value: 'LogInIcon' },
        { label: 'LogOutIcon', value: 'LogOutIcon' },
        { label: 'MailIcon', value: 'MailIcon' },
        { label: 'MapPinIcon', value: 'MapPinIcon' },
        { label: 'MapIcon', value: 'MapIcon' },
        { label: 'Maximize2Icon', value: 'Maximize2Icon' },
        { label: 'MaximizeIcon', value: 'MaximizeIcon' },
        { label: 'MehIcon', value: 'MehIcon' },
        { label: 'MenuIcon', value: 'MenuIcon' },
        { label: 'MessageCircleIcon', value: 'MessageCircleIcon' },
        { label: 'MessageSquareIcon', value: 'MessageSquareIcon' },
        { label: 'MicOffIcon', value: 'MicOffIcon' },
        { label: 'MicIcon', value: 'MicIcon' },
        { label: 'Minimize2Icon', value: 'Minimize2Icon' },
        { label: 'MinimizeIcon', value: 'MinimizeIcon' },
        { label: 'MinusCircleIcon', value: 'MinusCircleIcon' },
        { label: 'MinusSquareIcon', value: 'MinusSquareIcon' },
        { label: 'MinusIcon', value: 'MinusIcon' },
        { label: 'MonitorIcon', value: 'MonitorIcon' },
        { label: 'MoonIcon', value: 'MoonIcon' },
        { label: 'MoreHorizontalIcon', value: 'MoreHorizontalIcon' },
        { label: 'MoreVerticalIcon', value: 'MoreVerticalIcon' },
        { label: 'MousePointerIcon', value: 'MousePointerIcon' },
        { label: 'MoveIcon', value: 'MoveIcon' },
        { label: 'MusicIcon', value: 'MusicIcon' },
        { label: 'Navigation2Icon', value: 'Navigation2Icon' },
        { label: 'NavigationIcon', value: 'NavigationIcon' },
        { label: 'OctagonIcon', value: 'OctagonIcon' },
        { label: 'PackageIcon', value: 'PackageIcon' },
        { label: 'PaperclipIcon', value: 'PaperclipIcon' },
        { label: 'PauseCircleIcon', value: 'PauseCircleIcon' },
        { label: 'PauseIcon', value: 'PauseIcon' },
        { label: 'PenToolIcon', value: 'PenToolIcon' },
        { label: 'PercentIcon', value: 'PercentIcon' },
        { label: 'PhoneCallIcon', value: 'PhoneCallIcon' },
        { label: 'PhoneForwardedIcon', value: 'PhoneForwardedIcon' },
        { label: 'PhoneIncomingIcon', value: 'PhoneIncomingIcon' },
        { label: 'PhoneMissedIcon', value: 'PhoneMissedIcon' },
        { label: 'PhoneOffIcon', value: 'PhoneOffIcon' },
        { label: 'PhoneOutgoingIcon', value: 'PhoneOutgoingIcon' },
        { label: 'PhoneIcon', value: 'PhoneIcon' },
        { label: 'PieChartIcon', value: 'PieChartIcon' },
        { label: 'PlayCircleIcon', value: 'PlayCircleIcon' },
        { label: 'PlayIcon', value: 'PlayIcon' },
        { label: 'PlusCircleIcon', value: 'PlusCircleIcon' },
        { label: 'PlusSquareIcon', value: 'PlusSquareIcon' },
        { label: 'PlusIcon', value: 'PlusIcon' },
        { label: 'PocketIcon', value: 'PocketIcon' },
        { label: 'PowerIcon', value: 'PowerIcon' },
        { label: 'PrinterIcon', value: 'PrinterIcon' },
        { label: 'RadioIcon', value: 'RadioIcon' },
        { label: 'RefreshCcwIcon', value: 'RefreshCcwIcon' },
        { label: 'RefreshCwIcon', value: 'RefreshCwIcon' },
        { label: 'RepeatIcon', value: 'RepeatIcon' },
        { label: 'RewindIcon', value: 'RewindIcon' },
        { label: 'RotateCcwIcon', value: 'RotateCcwIcon' },
        { label: 'RotateCwIcon', value: 'RotateCwIcon' },
        { label: 'RssIcon', value: 'RssIcon' },
        { label: 'SaveIcon', value: 'SaveIcon' },
        { label: 'ScissorsIcon', value: 'ScissorsIcon' },
        { label: 'SearchIcon', value: 'SearchIcon' },
        { label: 'SendIcon', value: 'SendIcon' },
        { label: 'ServerIcon', value: 'ServerIcon' },
        { label: 'SettingsIcon', value: 'SettingsIcon' },
        { label: 'Share2Icon', value: 'Share2Icon' },
        { label: 'ShareIcon', value: 'ShareIcon' },
        { label: 'ShieldOffIcon', value: 'ShieldOffIcon' },
        { label: 'ShieldIcon', value: 'ShieldIcon' },
        { label: 'ShoppingBagIcon', value: 'ShoppingBagIcon' },
        { label: 'ShoppingCartIcon', value: 'ShoppingCartIcon' },
        { label: 'ShuffleIcon', value: 'ShuffleIcon' },
        { label: 'SidebarIcon', value: 'SidebarIcon' },
        { label: 'SkipBackIcon', value: 'SkipBackIcon' },
        { label: 'SkipForwardIcon', value: 'SkipForwardIcon' },
        { label: 'SlackIcon', value: 'SlackIcon' },
        { label: 'SlashIcon', value: 'SlashIcon' },
        { label: 'SlidersIcon', value: 'SlidersIcon' },
        { label: 'SmartphoneIcon', value: 'SmartphoneIcon' },
        { label: 'SmileIcon', value: 'SmileIcon' },
        { label: 'SpeakerIcon', value: 'SpeakerIcon' },
        { label: 'SquareIcon', value: 'SquareIcon' },
        { label: 'StarIcon', value: 'StarIcon' },
        { label: 'StopCircleIcon', value: 'StopCircleIcon' },
        { label: 'SunIcon', value: 'SunIcon' },
        { label: 'SunriseIcon', value: 'SunriseIcon' },
        { label: 'SunsetIcon', value: 'SunsetIcon' },
        { label: 'TabletIcon', value: 'TabletIcon' },
        { label: 'TagIcon', value: 'TagIcon' },
        { label: 'TargetIcon', value: 'TargetIcon' },
        { label: 'TerminalIcon', value: 'TerminalIcon' },
        { label: 'ThermometerIcon', value: 'ThermometerIcon' },
        { label: 'ThumbsDownIcon', value: 'ThumbsDownIcon' },
        { label: 'ThumbsUpIcon', value: 'ThumbsUpIcon' },
        { label: 'ToggleLeftIcon', value: 'ToggleLeftIcon' },
        { label: 'ToggleRightIcon', value: 'ToggleRightIcon' },
        { label: 'ToolIcon', value: 'ToolIcon' },
        { label: 'Trash2Icon', value: 'Trash2Icon' },
        { label: 'TrashIcon', value: 'TrashIcon' },
        { label: 'TrelloIcon', value: 'TrelloIcon' },
        { label: 'TrendingDownIcon', value: 'TrendingDownIcon' },
        { label: 'TrendingUpIcon', value: 'TrendingUpIcon' },
        { label: 'TriangleIcon', value: 'TriangleIcon' },
        { label: 'TruckIcon', value: 'TruckIcon' },
        { label: 'TvIcon', value: 'TvIcon' },
        { label: 'TwitchIcon', value: 'TwitchIcon' },
        { label: 'TwitterIcon', value: 'TwitterIcon' },
        { label: 'TypeIcon', value: 'TypeIcon' },
        { label: 'UmbrellaIcon', value: 'UmbrellaIcon' },
        { label: 'UnderlineIcon', value: 'UnderlineIcon' },
        { label: 'UnlockIcon', value: 'UnlockIcon' },
        { label: 'UploadCloudIcon', value: 'UploadCloudIcon' },
        { label: 'UploadIcon', value: 'UploadIcon' },
        { label: 'UserCheckIcon', value: 'UserCheckIcon' },
        { label: 'UserMinusIcon', value: 'UserMinusIcon' },
        { label: 'UserPlusIcon', value: 'UserPlusIcon' },
        { label: 'UserXIcon', value: 'UserXIcon' },
        { label: 'UserIcon', value: 'UserIcon' },
        { label: 'UsersIcon', value: 'UsersIcon' },
        { label: 'VideoOffIcon', value: 'VideoOffIcon' },
        { label: 'VideoIcon', value: 'VideoIcon' },
        { label: 'VoicemailIcon', value: 'VoicemailIcon' },
        { label: 'Volume1Icon', value: 'Volume1Icon' },
        { label: 'Volume2Icon', value: 'Volume2Icon' },
        { label: 'VolumeXIcon', value: 'VolumeXIcon' },
        { label: 'VolumeIcon', value: 'VolumeIcon' },
        { label: 'WatchIcon', value: 'WatchIcon' },
        { label: 'WifiOffIcon', value: 'WifiOffIcon' },
        { label: 'WifiIcon', value: 'WifiIcon' },
        { label: 'WindIcon', value: 'WindIcon' },
        { label: 'XCircleIcon', value: 'XCircleIcon' },
        { label: 'XOctagonIcon', value: 'XOctagonIcon' },
        { label: 'XSquareIcon', value: 'XSquareIcon' },
        { label: 'XIcon', value: 'XIcon' },
        { label: 'YoutubeIcon', value: 'YoutubeIcon' },
        { label: 'ZapOffIcon', value: 'ZapOffIcon' },
        { label: 'ZapIcon', value: 'ZapIcon' },
        { label: 'ZoomInIcon', value: 'ZoomInIcon' },
        { label: 'ZoomOutIcon', value: 'ZoomOutIcon' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/admin/categories/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'admin:faq-categories-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        position: this.item.position,
        type: 'faq',
        status: this.item.status,
        icon: this.item.icon,
        description: this.item.description,
      }

      this.$http.put(`/api/admin/categories/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'admin:faq-categories-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        position: item.position,
        status: item.status,
        icon: item.icon,
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
      })

      return data
    },
  },
}
</script>

<style type="text/scss">
.icon_select {
  flex-wrap: nowrap;
}
.icon_select .v-select {
  width: 100%;
}
</style>
